<template>
  <div class="custom-select" @blur="open = false">
    <div
      class="selected"
      :class="{ open: open }"
      @click.stop.prevent="toggleSelect"
    >
      {{ currentLocale }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="(locale, i) of localesList"
        :key="i"
        @click="changeLocale(locale)"
      >
        {{ locale.name }}
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import { setCookie } from "../../utils/cookies";
export default {
  data() {
    return {
      currentLocale: "",
      availableLocales: [],
      open: false,
    };
  },
  mounted() {
    this.currentLocale = this.$i18n.locale.toUpperCase();
    _.forEach(this.$i18n.availableLocales, (locale) => {
      this.availableLocales.push({ key: locale, name: locale.toUpperCase() });
    });
  },
  methods: {
    changeLocale(locale) {
      this.currentLocale = locale.name;
      this.$i18n.locale = locale.key;
      setCookie("locale", locale.key);
      this.$router.push({
        params: { locale: locale.key },
      });
      this.toggleSelect();
    },
    toggleSelect() {
      if (this.open) {
        window.removeEventListener("click", this.docClick);
        this.open = false;
      } else {
        window.addEventListener("click", this.docClick);
        this.open = true;
      }
    },
    docClick() {
      this.toggleSelect();
    },
  },
  computed: {
    localesList() {
      return _.filter(this.availableLocales, (locale) => {
        return locale.name !== this.currentLocale;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../vars";
.custom-select {
  position: relative;
  width: 200px;
  text-align: left;
  outline: none;
  height: 47px;
  line-height: 47px;
  margin: 25px auto;
  .selected {
    border-radius: 20px;
    border: 1px solid #666666;
    color: #fff;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
    &.open {
      border: 1px solid #ad8225;
      border-radius: 20px 20px 0px 0px;
    }
    &:after {
      position: absolute;
      content: "";
      top: 22px;
      right: 1em;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-color: #fff transparent transparent transparent;
    }
  }
  .items {
    color: #fff;
    border-radius: 0px 0px 20px 20px;
    overflow: hidden;
    border-right: 1px solid #ad8225;
    border-left: 1px solid #ad8225;
    border-bottom: 1px solid #ad8225;
    position: absolute;
    background-color: rgba(25, 25, 25, 0.5);
    left: 0;
    right: 0;
    z-index: 1;
    div {
      color: #fff;
      padding-left: 1em;
      cursor: pointer;
      user-select: none;
      &:hover {
        background-color: #ad8225;
      }
    }
  }
}
.selectHide {
  display: none;
}
@media (min-width: $large_w) {
  .custom-select {
    margin: 2px 50px 0 20px;
    width: 80px;
  }
}
</style>
