<template>
  <header>
    <div
      class="site-header-container"
      :class="{
        'site-header-container-mobile-open': isMobileMenuOpen,
        'site-header-container-minimize': isMenuMinimized,
      }"
    >
      <div class="site-header-top">
        <div class="site-header-left">
          <img class="site-header-logo" src="@/assets/logo.svg" alt="" />
        </div>
        <div class="site-header-burger">
          <toggle-menu-button
            @click="toggleMobileMenu"
            :isActive="isMobileMenuOpen"
          />
        </div>
      </div>

      <nav class="site-header-menu" v-show="isMenuOpen">
        <ul class="site-header-menu-list">
          <li v-for="(item, index) in pages" :key="index">
            <router-link
              tag="a"
              exact="true"
              class="site-header-menu-item text_medium"
              :to="'/' + this.$i18n.locale + item.path"
              @click="toggleMobileMenu"
              >{{ $t(item.name) }}</router-link
            >
          </li>
          <li>
            <uiSelectLangComponent />
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
import toggleMenuButton from "./UI/toggleMenuButton";
import uiSelectLangComponent from "./UI/uiSelectLangComponent";
export default {
  components: {
    toggleMenuButton,
    uiSelectLangComponent,
  },
  name: "SiteHeader",
  data() {
    return {
      windowWidth: 0,
      scroll: 0,
      isMobileMenuOpen: false,
      isMenuMinimized: false,
      panelHeight: 100,
      pages: [
        { name: "menu.home", path: "" },
        { name: "menu.aboutArmor", path: "/about_armor" },
        { name: "menu.demidovArmor", path: "/demidov_armor" },
        { name: "menu.products", path: "/products" },
        { name: "menu.orderNow", path: "/order" },
        { name: "menu.contacts", path: "/contacts" },
      ],
    };
  },
  methods: {
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
      if (this.isMobileMenuOpen && this.windowWidth < 1025) {
        document.documentElement.style.overflow = "hidden";
      } else {
        document.documentElement.style.overflow = "inherit";
      }
    },
    handleScroll() {
      this.scroll = window.scrollY;
      if (this.scroll > this.panelHeight) {
        if (!this.isMenuMinimized) this.isMenuMinimized = true;
      } else {
        if (this.isMenuMinimized) this.isMenuMinimized = false;
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    isMenuOpen() {
      if (this.windowWidth >= 1025) return true;
      return this.isMobileMenuOpen;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../vars";
.site-header-container-mobile-open {
  height: 100%;
  transition: all 0.6s linear;
  opacity: 1 !important;
}
.site-header-container {
  z-index: 10000;
  transition: all 0.6s linear;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .site-header-container {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background-color: rgba(14, 14, 14, 0.7);
  }
}

.site-header-top {
  display: flex;
  flex-direction: row;
}
.site-header-left {
  flex-grow: 2;
  display: flex;
}
.site-header-right {
  flex-grow: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.site-header-menu {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
}
.site-header-logo {
  width: 150px;
  height: 150px;
  transition: 0.2s all;
}
.site-header-burger {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.site-header-menu-list {
  list-style: none;
  width: 60%;
}
.site-header-menu-item {
  color: white;
  align-items: center;
  text-align: center;

  text-decoration: none;
  width: 100%;
  padding: 15px 25px;
  display: block;
}

.router-link-exact-active {
  color: #ffbe4f;
}
.site-header-container-minimize {
  .site-header-logo {
    width: 50px;
    height: 50px;
  }
  .site-header-burger {
    justify-content: flex-end;
    margin-right: 25px;
  }
}

@media (min-width: $large_w) {
  .site-header-burger {
    display: none;
  }
  .site-header-menu {
    flex-direction: row;
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
  .site-header-menu-list {
    display: flex;
    width: auto;
  }
  .site-header-menu-item {
    width: auto;
  }
  .site-header-container {
    display: flex;
    flex-direction: row;
  }
  .site-header-container-mobile-open {
    height: auto;
  }
  .site-header-menu {
    .site-header-menu-list {
      .site-header-menu-item {
        font-size: 23px;
      }
    }
  }
}
</style>
