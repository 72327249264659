<template>
  <a :href="this.link" target="_blank" v-ripple class="social-button-container">
    <font-awesome-icon class="social-button-icon" :icon="['fab', this.icon]" />
  </a>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 50,
    },
    link: {
      type: String,
    },
  },
  name: "uiSocialButton",
};
</script>

<style scoped lang="scss">
.social-button-container {
  display: flex;
  color: #141414;
  background-color: #ffffff;
  border-radius: 50%;
  min-width: 30px;
  height: 30px;
  font-size: 20px;
  transition: all 0.1s ease-in-out;
  &:hover {
    transform: scale(1.25);
  }
}
.social-button-icon {
  margin: auto;
}
</style>
