<template>
  <div
    id="hamburger-icon"
    :class="{ active: isActive }"
    @click="toggleMobileMenu"
  >
    <span class="line line-1"></span>
    <span class="line line-2"></span>
    <span class="line line-3"></span>
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      default: "#ffffff",
    },
    lineWidth: {
      type: String,
      default: "50px",
    },
    lineHeight: {
      type: String,
      default: "3px",
    },
    iconHeight: {
      type: String,
      default: "40px",
    },
  },
  name: "toggleMenuButton",
};
</script>

<style lang="scss" scoped>
$color: v-bind(color);

$height-icon: 20px;
$width-line: 30px;
$height-line: 2px;

$transition-time: 0.4s;
$rotation: 45deg;
$translateY: ($height-icon / 2);
$translateX: 0;

#hamburger-icon {
  width: $width-line;
  height: $height-icon;
  position: relative;
  display: block;

  .line {
    display: block;
    background: white;
    width: $width-line;
    height: $height-line;
    position: absolute;
    left: 0;
    border-radius: ($height-line / 2);
    transition: all $transition-time;
    -webkit-transition: all $transition-time;
    -moz-transition: all $transition-time;

    &.line-1 {
      top: 0;
    }
    &.line-2 {
      top: 50%;
    }
    &.line-3 {
      top: 100%;
    }
  }
  &:hover,
  &:focus {
    .line-1 {
      transform: translateY($height-line / 2 * -1);
      -webkit-transform: translateY($height-line / 2 * -1);
      -moz-transform: translateY($height-line / 2 * -1);
    }
    .line-3 {
      transform: translateY($height-line / 2);
      -webkit-transform: translateY($height-line / 2);
      -moz-transform: translateY($height-line / 2);
    }
  }
  &.active {
    .line-1 {
      transform: translateY($translateY) translateX($translateX)
        rotate($rotation);
      -webkit-transform: translateY($translateY) translateX($translateX)
        rotate($rotation);
      -moz-transform: translateY($translateY) translateX($translateX)
        rotate($rotation);
    }
    .line-2 {
      opacity: 0;
    }
    .line-3 {
      transform: translateY($translateY * -1) translateX($translateX)
        rotate($rotation * -1);
      -webkit-transform: translateY($translateY * -1) translateX($translateX)
        rotate($rotation * -1);
      -moz-transform: translateY($translateY * -1) translateX($translateX)
        rotate($rotation * -1);
    }
  }
}
</style>
