<template>
  <footer class="site-footer-container">
    <div class="site-footer-social-links">
      <ui-social-button
        class="site-footer-social-link"
        icon="instagram"
        link="https://www.instagram.com/p/CUHfvImKI5f/?utm_medium=copy_link"
      ></ui-social-button>
      <ui-social-button
        class="site-footer-social-link"
        icon="youtube"
        link="https://www.youtube.com/channel/UCD0uP60omadK1sbyhcTviiQ"
      ></ui-social-button>
      <ui-social-button
        class="site-footer-social-link"
        icon="facebook"
        link="https://www.facebook.com/demidovarmor"
      ></ui-social-button>
      <ui-social-button
        class="site-footer-social-link"
        icon="twitter"
        link="https://twitter.com/Demidov_armor"
      ></ui-social-button>
      <ui-social-button
        class="site-footer-social-link"
        icon="linkedin"
        link="https://www.linkedin.com/in/demidov-armor-52547621a/"
      ></ui-social-button>
      <ui-social-button
        class="site-footer-social-link"
        icon="reddit"
        link="https://www.reddit.com/user/Demidov_Armor/"
      ></ui-social-button>
      <ui-social-button
        class="site-footer-social-link"
        icon="tumblr"
        link="https://www.tumblr.com/blog/view/demidovarmor"
      ></ui-social-button>
      <ui-social-button
        class="site-footer-social-link"
        icon="quora"
        link="https://www.quora.com/profile/Demidov-Armor"
      ></ui-social-button>
      <ui-social-button
        class="site-footer-social-link"
        icon="pinterest"
        link="https://www.pinterest.com/demidovarmor/_saved/"
      ></ui-social-button>
    </div>
    <div class="site-footer-copyright">
      <div>Copyright &copy; 2021 Demidov Armor. All Rights Reserved</div>
    </div>
  </footer>
</template>

<script>
import uiSocialButton from "./UI/uiSocialButton";
export default {
  components: {
    uiSocialButton,
  },
  name: "SiteFooter",
};
</script>

<style scoped lang="scss">
@import "../vars";
.site-footer-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  background-color: rgb(14, 14, 14);
}
.site-footer-social-links {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.site-footer-social-link {
  margin: 0 5px;
}
.site-footer-copyright {
  color: white;
  font-size: 15px;
  text-align: center;
  padding: 10px 0;
  border-top: 1px solid rgba(22, 22, 22, 0.93);
}
@media (min-width: $small_w) {
  .social-button-container {
    min-width: 40px;
    height: 40px;
    font-size: 30px;
  }
}
@media (min-width: $medium_w) {
  .social-button-container {
    min-width: 40px;
    height: 40px;
    font-size: 30px;
  }
  .site-footer-social-links {
    margin: 25px 0;
  }
}
</style>
